import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import utils from '../../../Utils/utils';
import dayjs from 'dayjs';
import cls from 'classname';
import Icon from '../../../Icon';
import { Dropdown } from 'antd';
import { FadspotComponent } from './AddLiibraryContent';
import './AdLibraryItem.less';

const AdLibraryModalItem = (
  {
    id,
    setModal,
    setDataToCalc,
    handleOpenModalShopifyConnect,
    data,
  }) => {
  const { t } = useTranslation();
  const isExtended = data.is_eu_targeted;
  const isMobile = useSelector(state => state?.nav?.isMobile);


  const [visible, setVisible] = useState(false);
  const [visibleTooltipAction, setVisibleTooltipAction] = useState(false);
  const [visibleTooltipHeadline, setVisibleTooltipHeadline] = useState(false);
  const [adLibrarySlide, setAdLibrarySlide] = useState(0);

  const handleVisibleChange = (value) => setVisible(value);

  const getDiff = (date) => {
    let today = dayjs();
    let yearDiff = today.diff(dayjs(date), 'years');
    let monthDiff = today.diff(dayjs(date), 'months');
    let dayDiff = today.diff(dayjs(date), 'days');
    return yearDiff ?
      `${yearDiff}y ago`
      :
      monthDiff ?
        `${monthDiff}m ago`
        :
        dayDiff ?
          `${dayDiff}d ago`
          :
          ''
  }

  return (
    <div className={cls("adLibrary-item adLibrary-item-modal", {
      extended: isExtended,
    })}
    >
      <div className="adLibrary-item-header">
        <div className="adLibrary-item-header-info-wrapper">
          <div className='adLibrary-item-header-logo-wrapper'>
            <img src={data?.collation?.advertiser?.profile_photo}
              alt=""
              className={'adLibrary-item-header-logo'}
              onError={utils.addDefaultSrc}
            />
          </div>
          <div className="adLibrary-item-header-title-wrapper">
            <span className="adLibrary-item-header-title">
              {
                `${data?.collation?.advertiser?.page_name}`
              }
              {data.is_verified && <Icon role={'icon'} type={'ad_spot_checkmark'} />}
            </span>
            <span className={cls("adLibrary-item-header-status", {
              red: !data?.is_active,
            })}>
              <div className={cls("adLibrary-item-header-status-circle", {
                red: !data?.is_active,
              })} />
              {!data?.is_active ? t('Currently inactive') : t('Currently active')}
            </span>
          </div>
        </div>
        {
          isMobile ?
            <span className="adLibrary-item-header-action"
              onClick={() => {
                setModal('ad_item_action');
                setDataToCalc({ title: `${data?.advertiser?.page_name} #${id}` });
              }}
            >
              <Icon type={'three_dots'} role={'icon'} />
            </span>
            :
            <Dropdown destroyPopupOnHide={true}
              getPopupContainer={(trigger) => trigger.parentNode}
              onOpenChange={handleVisibleChange}
              placement="bottomRight"
              dropdownRender={() => (
                <div className="adLibrary-item-header-action-dropdown">
                  <div className="adLibrary-item-header-action-dropdown-option"
                    onClick={() => window.open(`https://www.facebook.com/ads/library/?id=${data?.ad_archive_id}`,
                      '_blank')}
                  >
                    <Icon role={'icon'} type={'search_facebook'} isDark={false} />
                    <span>
                      {t('View Facebook Ad Library')}
                    </span>
                  </div>
                  <div className="adLibrary-item-header-action-dropdown-option"
                    onClick={() => {
                      setVisible(false);
                      handleOpenModalShopifyConnect(`storeId_productId`);
                    }}
                  >
                    <Icon type={'shopify_btn_icon'} role={'icon'} isLogo={true} />
                    <span>
                      {t('Import to Shopify store')}
                    </span>
                  </div>
                  <div className="adLibrary-item-header-action-dropdown-option"
                    onClick={() => window.open(`https://www.similarweb.com/website/${data?.collation?.domain_fld}`,
                      '_blank')}
                  >
                    <Icon role={'icon'} type={'similar_web'} isDark={false} />
                    <span>
                      {t('View with Similarweb')}
                    </span>
                  </div>
                </div>
              )}
              trigger={['click']}
              open={visible}
            >
              <span className="adLibrary-item-header-action">
                <Icon type={'three_dots'} role={'icon'} />
              </span>
            </Dropdown>
        }
      </div>
      <div className="adLibrary-item-stats">
        {data.ad_sets_count &&
        <div className="badge" onClick={() => {
          setVisibleModal(true);
        }}>
          <span>{data.ad_sets_count} {t('adsets')}</span>
          <span className="sales-tracker-top-page-credits-title">
            <Icon
              type={'attention_outline'}
              role="button"
              width={16}
              height={16}
              tooltipProps={{
                trigger: 'hover',
                placement: 'bottom',
                overlayClassName: 'subscription-tooltip scheduled',
                getPopupContainer: (trigger) => trigger.parentNode,
              }}
              titleText={t('Define the quantity of adsets used in the Ad')}
            />
          </span>
        </div>}
      </div>
      <p className="adLibrary-item-description">
        {
          data?.collation?.creative_type === 'carousel' ?  data?.collation?.snapshot?.cards?.[adLibrarySlide]?.body :  data?.collation?.snapshot?.body?.text
        }
      </p>
      {
        isExtended ?
          <div className="adLibrary-item-target-wrapper">
            <div className="adLibrary-item-target">
              <span className="adLibrary-item-target-title">
                {t('Region')}
              </span>
              <span className="adLibrary-item-target-value-with-flag">
                <Icon type={'e_u_flag'} />
                <span>EU</span>
              </span>
            </div>
            <div className="adLibrary-item-target">
              <span className="adLibrary-item-target-title">
                {t('Spent')}
              </span>
              <span className="adLibrary-item-target-value">
              {
                data?.total_estimated_spend?.min === data?.total_estimated_spend?.max
                  ? data?.total_estimated_spend?.min
                    ? `$${utils.rounded(data?.total_estimated_spend?.min,2)}`
                    : 'No Data'
                  : data?.total_estimated_spend?.min && data?.total_estimated_spend?.max
                    ? `$${utils.rounded(data?.total_estimated_spend?.min,2)} - $${utils.rounded(data?.total_estimated_spend?.max,2)}`
                    : 'No Data'
              }
              </span>
            </div>
            <div className="adLibrary-item-target">
              <span className="adLibrary-item-target-title">
                {t('Reach')}
              </span>
              <span className="adLibrary-item-target-value">
                {
                  `${utils.convertNumber(data?.total_reaches_count)}`
                }
              </span>
            </div>
          </div>
          :
          null
      }
      {/* { isExtended ?
          <div className="adLibrary-item-insights">
           <span onClick={() => {
            ('ad_item_ad_spend');
         setDataToCalc({title: `${data?.advertiser?.name} #${id}`});
          }}>
             {
               t('View ad insights')
             }
        </span>
         </div>
         :
       null
        } */}

      <div className={cls('fadspot-page-item-wrapper', { addLibrary: true })}>
        <div className='fadspot-page-item'>
          <FadspotComponent data={data.collation} visibleTooltipAction={visibleTooltipAction} visibleTooltipHeadline={visibleTooltipHeadline} setAdLibrarySlide={setAdLibrarySlide}/>
        </div>
      </div>

      <div className="adLibrary-item-info-wrapper">
        {isExtended &&
          <div className="adLibrary-item-info">
            <span className="adLibrary-item-info-title">
              {t('Countries')}
            </span>
            <div className="adLibrary-item-info-value">
              {Array.isArray(data?.demographics?.country) ? (
                <div className="country-flags">
                  {data.demographics.country.slice(0, 4).map((countryCode, index) => (
                    <div
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: countryCode.flag,
                      }}
                    />
                  ))}
                  {data.demographics.country.length > 4 && (
                    <span className="extra-flags">+{data.demographics.country.length - 4}</span>
                  )}
                </div>
              ) : (
                data?.demographics?.country || null
              )}
            </div>
          </div>
        }
        {isExtended && typeof data?.demographics?.gender === 'string' &&
          <div className="adLibrary-item-info">
            <span className="adLibrary-item-info-title">
              {t('Gender')}
            </span>
            <div className="adLibrary-item-info-value">
              {data?.demographics?.gender || 'all'}
            </div>
          </div>
        }
        {isExtended &&
          <div className="adLibrary-item-info">
            <span className="adLibrary-item-info-title">
              {t('Ages')}
            </span>

            {typeof data?.demographics?.age === 'string' ?
              <div className="adLibrary-item-info-value">
                {data?.demographics?.age || 'any'}
              </div>
              :
              <div className="adLibrary-item-info-value">
                {`${data?.demographics?.age?.min} - ${data?.demographics?.age?.max}`}
              </div>}
          </div>
        }
        <div className="adLibrary-item-info">
          <span className="adLibrary-item-info-title">
            {t('Creation date')}
          </span>
          <div className="adLibrary-item-info-value">
            {dayjs(data?.created_at).format('D.MM.YYYY')}
            <span className="adLibrary-item-info-value-gray">
              {getDiff(data?.created_at)}
            </span>
          </div>
        </div>
        <div className="adLibrary-item-info">
          <span className="adLibrary-item-info-title">
            {t('Last seen')}
          </span>
          <div className="adLibrary-item-info-value">
            {dayjs(data?.last_seen_date).format('D.MM.YYYY')}
            <span className='adLibrary-item-info-value-gray'>
              {getDiff(data?.last_seen_date)}
            </span>
          </div>
        </div>
      </div>
      </div>
  )
}


export default AdLibraryModalItem;
