import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  getAdLibraryFiltersRequest: null,
  getAdLibraryFiltersSuccess: ['payload'],
  getAdLibraryFiltersFailure: ['payload'],

  getAdLibraryPresetsRequest: null,
  getAdLibraryPresetsSuccess: ['payload'],
  getAdLibraryPresetsFailure: ['payload'],

  createAdLibraryPresetRequest: ['payload'],
  createAdLibraryPresetSuccess: ['payload'],
  createAdLibraryPresetFailure: ['payload'],

  deleteAdLibraryPresetRequest: ['payload'],
  deleteAdLibraryPresetSuccess: ['payload'],
  deleteAdLibraryPresetFailure: ['payload'],

  updateAdLibraryPresetRequest: ['payload'],
  updateAdLibraryPresetSuccess: ['payload'],
  updateAdLibraryPresetFailure: ['payload'],

  getAdLibraryAdsRequest: ['payload'],
  getAdLibraryAdsSuccess: ['payload'],
  getAdLibraryAdsFailure: ['payload'],

  getAdLibraryAdsDefaultRequest: ['payload'],
  getAdLibraryAdsDefaultSuccess: ['payload'],
  getAdLibraryAdsDefaultFailure: ['payload'],


  getAdLibraryAdsCollationRequest: ['payload'],
  getAdLibraryAdsCollationSuccess: ['payload'],
  getAdLibraryAdsCollationFailure: ['payload'],

  resetCreateResult: null,
  resetCreateError: null,
  setSelectedQuickPreset: ['payload'],

  setSelectedFiltersAdLibrary: ['payload'],
});

export const AdLibraryTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  loading: false,
  adsLoading: false,
  filtersLoading: false,
  loadingAds:false,
  errors: null,
  filters: {},
  presets: {},
  creditsLeft: null,
  selectedFilters: {},
  ads: {},
  collations: {},
  createError: false,
  createResult: false,
  selectedQuickPreset: {},
});

const getAdLibraryFiltersRequest = (state) =>
  state.merge({ filtersLoading: true });

const getAdLibraryFiltersSuccess = (state, { payload: data }) =>
  state.merge({ filtersLoading: false, filters: data });

const getAdLibraryFiltersFailure = (state, { payload: { errors } }) =>
  state.merge({ filtersLoading: false, errors })

const getAdLibraryPresetsRequest = (state) =>
  state.merge({ loading: true });

const getAdLibraryPresetsSuccess = (state, { payload: data }) => {
  const {credits_left, ...presets} = data
  return  state.merge({ loading: false, creditsLeft: credits_left,
    presets: {...presets}});
}

const getAdLibraryPresetsFailure = (state, { payload: { errors } }) =>
  state.merge({ loading: false, errors });

const createAdLibraryPresetRequest = (state) =>
  state.merge({ loading: true });

const createAdLibraryPresetSuccess = (state, { payload: data }) => {
  const newUserPresets = [data, ...state.presets?.users]
  return state.merge({loading: false, presets: {...state.presets, users: newUserPresets}, createResult: true });
}
const createAdLibraryPresetFailure = (state, { payload: { errors } }) =>
  state.merge({ loading: false, errors, });

const deleteAdLibraryPresetRequest = (state) =>
  state.merge({ loading: true });

const deleteAdLibraryPresetSuccess = (state, { payload: id }) => {
  const newUserPresets = [...state.presets?.users.filter(el => el.id !== id)]
  return state.merge({loading: false, presets: {...state.presets, users: newUserPresets}});
}
const deleteAdLibraryPresetFailure = (state, { payload: { errors } }) =>
  state.merge({ loading: false, errors });

const updateAdLibraryPresetRequest = (state) =>
  state.merge({ loading: true });

const updateAdLibraryPresetSuccess = (state, { payload: data }) => {
  const newUserPresets = [...state.presets?.users.map(el => {
    if (el.id === data.id) return data
    else return el
  })]
  return state.merge({loading: false, presets: {...state.presets, users: newUserPresets} });
}

const updateAdLibraryPresetFailure = (state, { payload: { errors } }) =>
  state.merge({ loading: false, errors });

const getAdLibraryAdsRequest = (state) =>
  state.merge({ adsLoading: true });

const getAdLibraryAdsSuccess = (state, { payload: data }) =>{
  const { page, results,credits_left } = data;

  if (page === 1) {
    return state.merge({
      adsLoading: false,
      ads: { ...data },
      creditsLeft: credits_left,
    });
  } else {
    return state.merge({
      adsLoading: false,
      ads: {
        ...data,
        results: [...(state?.ads?.results || []), ...results],
        creditsLeft: credits_left,
      },
    });
  }
}

const getAdLibraryAdsFailure = (state, { payload: { errors } }) =>
  state.merge({ adsLoading: false, errors });

const getAdLibraryAdsDefaultRequest = (state) =>{
  return state.merge({ adsLoading: true });
}
const getAdLibraryAdsDefaultSuccess = (state, { payload: data }) => {
  const { page, results } = data;

  if (page === 1) {
    return state.merge({
      adsLoading: false,
      ads: { ...data },
    });
  } else {
    return state.merge({
      adsLoading: false,
      ads: {
        ...data,
        results: [...(state?.ads?.results || []), ...results]
      },
    });
  }
};


const getAdLibraryAdsDefaultFailure = (state, { payload: { errors } }) =>
  state.merge({ adsLoading: false, errors });

const getAdLibraryAdsCollationRequest = (state) =>{
  return state.merge({ loadingAds: true });
}

const getAdLibraryAdsCollationSuccess = (state, { payload: data }) => {
  const { page, adsets } = data;
  if (page === 1) {
    return state.merge({
      loadingAds: false,
      collations: { ...data },
    });
  } else {
    return state.merge({
      loadingAds: false,
      collations: {
        ...data,
        adsets: [
          ...(state?.collations?.adsets || []),
          ...adsets,
        ],
      },
    });
  }
};

const getAdLibraryAdsCollationFailure = (state, { payload: { errors } }) =>
  state.merge({ loadingAds: false, errors, collations:{} });

const setSelectedFiltersAdLibrary = (state, {payload: data}) =>
  state.merge({ selectedFilters: data })

const resetCreateResult = (state) =>
  state.merge({createResult: false})

const resetCreateError = (state) =>
  state.merge({createError: false})

const setSelectedQuickPreset = (state, {payload: data}) =>
  state.merge({ selectedQuickPreset: data })

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_AD_LIBRARY_FILTERS_REQUEST]: getAdLibraryFiltersRequest,
  [Types.GET_AD_LIBRARY_FILTERS_SUCCESS]: getAdLibraryFiltersSuccess,
  [Types.GET_AD_LIBRARY_FILTERS_FAILURE]: getAdLibraryFiltersFailure,

  [Types.GET_AD_LIBRARY_PRESETS_REQUEST]: getAdLibraryPresetsRequest,
  [Types.GET_AD_LIBRARY_PRESETS_SUCCESS]: getAdLibraryPresetsSuccess,
  [Types.GET_AD_LIBRARY_PRESETS_FAILURE]: getAdLibraryPresetsFailure,

  [Types.CREATE_AD_LIBRARY_PRESET_REQUEST]: createAdLibraryPresetRequest,
  [Types.CREATE_AD_LIBRARY_PRESET_SUCCESS]: createAdLibraryPresetSuccess,
  [Types.CREATE_AD_LIBRARY_PRESET_FAILURE]: createAdLibraryPresetFailure,

  [Types.DELETE_AD_LIBRARY_PRESET_REQUEST]: deleteAdLibraryPresetRequest,
  [Types.DELETE_AD_LIBRARY_PRESET_SUCCESS]: deleteAdLibraryPresetSuccess,
  [Types.DELETE_AD_LIBRARY_PRESET_FAILURE]: deleteAdLibraryPresetFailure,

  [Types.UPDATE_AD_LIBRARY_PRESET_REQUEST]: updateAdLibraryPresetRequest,
  [Types.UPDATE_AD_LIBRARY_PRESET_SUCCESS]: updateAdLibraryPresetSuccess,
  [Types.UPDATE_AD_LIBRARY_PRESET_FAILURE]: updateAdLibraryPresetFailure,

  [Types.GET_AD_LIBRARY_ADS_REQUEST]: getAdLibraryAdsRequest,
  [Types.GET_AD_LIBRARY_ADS_SUCCESS]: getAdLibraryAdsSuccess,
  [Types.GET_AD_LIBRARY_ADS_FAILURE]: getAdLibraryAdsFailure,

  [Types.GET_AD_LIBRARY_ADS_DEFAULT_REQUEST]: getAdLibraryAdsDefaultRequest,
  [Types.GET_AD_LIBRARY_ADS_DEFAULT_SUCCESS]: getAdLibraryAdsDefaultSuccess,
  [Types.GET_AD_LIBRARY_ADS_DEFAULT_FAILURE]: getAdLibraryAdsDefaultFailure,

  [Types.GET_AD_LIBRARY_ADS_COLLATION_REQUEST]: getAdLibraryAdsCollationRequest,
  [Types.GET_AD_LIBRARY_ADS_COLLATION_SUCCESS]: getAdLibraryAdsCollationSuccess,
  [Types.GET_AD_LIBRARY_ADS_COLLATION_FAILURE]: getAdLibraryAdsCollationFailure,

  [Types.SET_SELECTED_FILTERS_AD_LIBRARY]: setSelectedFiltersAdLibrary,
  [Types.RESET_CREATE_RESULT]: resetCreateResult,
  [Types.RESET_CREATE_ERROR]: resetCreateError,
  [Types.SET_SELECTED_QUICK_PRESET]: setSelectedQuickPreset,
});
