import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import dayjs from 'dayjs';
import acc from 'accounting';
import utils from '../../../Utils/utils';
import Image from '../../../Components/Image';
import Images from '../../../Images';
import { Divider } from 'antd';
import Icon from '../../../Icon';
import { DropdownRender as StoreDropdownRender } from './SalesStoreTableColumns';
import { DropdownRender as ProductDropdownRender } from './SalesProductsTableColumns';
import './UserTrackingCard.less';

const UserTrackingCard = (
  {
    view,
    data,
    isMobile,
    isTrial,
    setModal,
    setRecordToDelete,
  },
) => {

  const {t} = useTranslation();

  const theme = useSelector((state) => state.nav.theme);

  return (
    <div className="user-tracking-card">
      <div className="user-tracking-card-image">
        {data?.logo || data?.favicon || data?.main_image ?
          <img src={data?.logo || data?.favicon || data?.main_image}
               alt="" onError={utils.addDefaultSrc}
          />
          :
          <Image src={Images.defaultImg} small={true} />
        }
      </div>
      <div className="user-tracking-card-title">
        <span>
          {data?.title}
        </span>
      </div>
      <div className="user-tracking-card-info">
        <div className="row">
          <span className="key">
            {t('30 days sales')}
          </span>
          <span className="value">
            {
              data?.month_sales ?
                <span>{acc.format(data?.month_sales, 0, ',', '.')} {data?.month_sales === 1 ? t('Sale') : t('Sales')}</span>
                :
                <span>-</span>
            }
            <Divider type="vertical" style={{ margin: '0' }} />
            {
              data?.month_revenue ?
                <span>${acc.format(data?.month_revenue, 2, ',', '.')}</span>
                :
                <span>-</span>
            }
          </span>
        </div>
        <div className="row">
          <span className="key">
            {t('Creation date')}
          </span>
          <span className="value">
            {
              data?.created_at ? dayjs(data?.created_at).format('MMMM DD, YYYY') : '-'
            }
          </span>
        </div>
        <div className="row">
          <span className="key">
            {t('Links')}
          </span>
          <span className="value">
            {
              view === 'stores' ?
                <a
                  key={'facebook'}
                  href={`https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&q=${data?.custom_domain?.includes('www') ? data?.custom_domain?.split('.')?.filter(el => el !== 'www').join('.') : data?.custom_domain}&search_type=keyword_unordered&media_type=all`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link with-img"
                >
                  <Icon role="button"
                        type={`search_facebook`}
                        className={`facebook-icon`}
                        width={20}
                        height={20}
                        titleText={() => <>Search on <span style={{ textTransform: 'capitalize' }}>Facebook</span></>}
                        tooltipProps={{
                          placement: 'bottom',
                          trigger: 'hover',
                          destroyTooltipOnHide: true,
                          overlayClassName: cls('details-tooltip', `facebook-tooltip`),
                          getPopupContainer: () => document.getElementById('product-database-table-body'),
                        }}
                  />
                </a>
                :
                data?.quick_search?.length ?
                  data?.quick_search?.map(el => (
                    <a
                      key={data?.title + el?.platform}
                      href={el?.link ? el?.link : '#'}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link with-img"
                    >
                      <Icon role="button"
                            type={`search_${el?.platform}`}
                            theme={theme}
                            className={`${el?.platform}-icon`}
                            width={el?.platform === 'alibaba' ? 24 : 16}
                            height={el?.platform === 'alibaba' ? 24 : 16}
                            titleText={() => <>Search on <span style={{textTransform: 'capitalize'}}>{el?.platform}</span></>}
                            tooltipProps={{
                              placement: 'bottom',
                              trigger: 'hover',
                              destroyTooltipOnHide: true,
                              overlayClassName: cls('details-tooltip', `${el?.platform}-tooltip`),
                              getPopupContainer: () =>
                                document.getElementById('product-database-table-body'),
                            }}
                      />
                    </a>
                  ))
                  :
                  '-'
            }
          </span>
        </div>
      </div>
      <div className="user-tracking-card-btn-wrapper">
        {
          view === 'stores' ?
            <a href={`/sales-tracker/stores/store/${data?.id}`}
               target="_self"
               className="user-tracking-card-btn"
            >
              {t('Store insights')}
            </a>
            :
            <a href={`/sales-tracker/products/product/${data?.id}`}
               target="_self"
               className="user-tracking-card-btn"
            >
              {t('Product insights')}
            </a>
        }
        {
          view === 'stores' ?
            <StoreDropdownRender t={t}
                                 data={data}
                                 isMobile={isMobile}
                                 isTrial={isTrial}
                                 setModal={setModal}
                                 setRecordToDelete={setRecordToDelete}

            />
            :
            <ProductDropdownRender t={t}
                                 data={data}
                                 isMobile={isMobile}
                                 isTrial={isTrial}
                                 setModal={setModal}
                                 setRecordToDelete={setRecordToDelete}

            />
        }
      </div>
    </div>
  );
};

export default UserTrackingCard;
