import React from 'react';
import { Document, Page, Text, View, Image, Link } from '@react-pdf/renderer';
import utils from '../../Utils/utils';
import dayjs from 'dayjs';
import acc from 'accounting';
import styles from './InvoicePDFStyles';
const logoInvoice = require('../../Images/logo-invoice.png');
const heartRed = require('../../Images/heart-red.png');
const heartGreen = require('../../Images/heart-green.png');

const  InvoicePDF = (
  {
    user,
    invoice
  }) => {

  const { email } = user;

  const {
    invoice_id,
    total,
    status,
    issued_date,
    payment_method,
    items,
    bill_from,
    billing,
    credits_applied,
    discount,
  } = invoice;

  return (
    <Document author="dropship" title="Invoice">
      <Page size="A4" style={styles.page}>
        <View style={{ ...styles.invoice_header }}>
          <View style={{ ...styles.invoice_header.info }}>
            <Text style={{ ...styles.invoice_header.info.title }}>
              Invoice
            </Text>
            <View style={{ ...styles.invoice_header.info.subtitle }}>
              <Text style={{ ...styles.invoice_header.info.subtitle.id }}>
                #{invoice_id}
              </Text>
              <View style={{ ...styles.divider, ...styles.divider.vertical, margin: '0 8px' }} />
              <Text style={{ ...styles.invoice_header.info.subtitle.method }}>
                {
                  payment_method ?
                    `${utils.toUpperLatter(payment_method?.card_type)} *${payment_method?.last_4}`
                    :
                    '-'
                }
              </Text>
            </View>
          </View>
          <View style={{ ...styles.invoice_header.logo }}>
            <Image source={logoInvoice} />
          </View>
        </View>
        <View style={{ ...styles.invoice_status, ...(status?.id !== 1 ? { ...styles.invoice_status.due } : {}) }}>
          <Text style={{ ...styles.invoice_status.text }}>
            {
              status?.id === 1 ?
                'Invoice paid'
                :
                'Total due'
            }
          </Text>
          <Text
            style={{ ...styles.invoice_status.date, ...(status?.id !== 1 ? { ...styles.invoice_status.due.date } : {}) }}>
            {
              dayjs(issued_date).format('MMMM DD, YYYY')
            }
          </Text>
          <Text
            style={{ ...styles.invoice_status.text, ...(status?.id !== 1 ? { ...styles.invoice_status.due.text } : {}) }}>
            ${acc.formatNumber(total / 100, 2, ' ', ',')}
          </Text>
        </View>
        <View style={{ ...styles.divider, ...styles.divider.horizontal, margin: '24px 0 16px' }} />
        <View style={{ ...styles.invoice_billing_info }}>
          <View style={{ ...styles.invoice_billing_info.block }}>
            <Text style={{ ...styles.invoice_billing_info.block.title }}>
              Bill from
            </Text>
            <Text style={{ ...styles.invoice_billing_info.block.value }}>
              {bill_from?.name}
            </Text>
            <Text style={{ ...styles.invoice_billing_info.block.value }}>
              {bill_from?.['VAT']}
            </Text>
            <Text style={{ ...styles.invoice_billing_info.block.value, marginBottom: 0 }}>
              {`${bill_from?.['address_line_1']} ${bill_from?.['address_line_2'] || ''}, ${bill_from?.country} ${bill_from?.state || ''}, ${bill_from?.city}, ${bill_from?.postal_code || bill_from?.zip}`}
            </Text>
          </View>
          <View style={{ ...styles.invoice_billing_info.block }}>
            <Text style={{ ...styles.invoice_billing_info.block.title }}>
              Bill to
            </Text>
            <Text style={{ ...styles.invoice_billing_info.block.value }}>
              {billing?.company ? billing?.company : `${billing?.first_name} ${billing?.last_name}`}
            </Text>
            <Text style={{ ...styles.invoice_billing_info.block.value }}>
              {billing?.company && billing?.['vat_number'] ? billing?.['vat_number'] : `${email}`}
            </Text>
            <Text style={{ ...styles.invoice_billing_info.block.value, marginBottom: 0 }}>
              {`${billing?.['line_1'] || ''} ${bill_from?.['line_2'] || ''} ${billing?.country} ${billing?.state || ''}, ${billing?.city}, ${billing?.postal_code || billing?.zip}`}
            </Text>
          </View>
        </View>
        <View style={{ ...styles.divider, ...styles.divider.horizontal, margin: '24px 0 32px' }} />
        <View style={{ ...styles.invoice_billing_details }}>
          <View style={{ ...styles.invoice_billing_details.row }}>
            <Text style={{ ...styles.invoice_billing_details.row.title }}>
              Plan
            </Text>
            <Text style={{ ...styles.invoice_billing_details.row.title }}>
              Bill cycle
            </Text>
            <Text style={{ ...styles.invoice_billing_details.row.title }}>
              Amount
            </Text>
          </View>
          <View style={{ ...styles.divider, ...styles.divider.horizontal, margin: '12px 0 16px' }} />
          {
            items.map(item => (
              <View style={{ ...styles.invoice_billing_details.row, ...styles.invoice_billing_details.row.row_value }}
                    key={item?.plan?.plan?.id}
              >
                <Text style={{ ...styles.invoice_billing_details.row.value }}>
                  {utils.toUpperLatter(item?.plan?.plan?.name) || item?.label}
                </Text>
                <Text style={{ ...styles.invoice_billing_details.row.value, justifyContent: 'center' }}>
                  {items?.[0]?.['bill_cycle'] || '-'}
                </Text>
                <Text style={{ ...styles.invoice_billing_details.row.value, justifyContent: 'flex-end' }}>
                  ${acc.formatNumber(item?.price / 100, 2, ' ', ',')}
                </Text>
              </View>
            ))
          }
          {
            discount ?
              <View style={{ ...styles.invoice_billing_details.row, ...styles.invoice_billing_details.row.row_value }}>
                <Text style={{ ...styles.invoice_billing_details.row.value }}>
                  Discount
                </Text>
                <Text style={{ ...styles.invoice_billing_details.row.value, justifyContent: 'center' }} />
                <Text style={{ ...styles.invoice_billing_details.row.value, justifyContent: 'flex-end' }}>
                  ${acc.formatNumber(discount / 100, 2, ' ', ',')}
                </Text>
              </View>
              :
              null
          }
          {
            credits_applied ?
              <View style={{ ...styles.invoice_billing_details.row, ...styles.invoice_billing_details.row.row_value }}>
                <Text style={{ ...styles.invoice_billing_details.row.value }}>
                  Credits applied
                </Text>
                <Text style={{ ...styles.invoice_billing_details.row.value, justifyContent: 'center' }} />
                <Text style={{ ...styles.invoice_billing_details.row.value, justifyContent: 'flex-end' }}>
                  ${acc.formatNumber(credits_applied / 100, 2, ' ', ',')}
                </Text>
              </View>
              :
              null
          }
          <View style={{
            ...styles.divider, ...styles.divider.horizontal,
            margin: '12px 0 16px', ...(status?.id !== 1 ? { ...styles.divider.due } : {})
          }} />
          <View style={{ ...styles.invoice_billing_details.row }}>
            <Text style={{ ...styles.invoice_billing_details.row.value }}>
              Total
            </Text>
            <Text style={{ ...styles.invoice_billing_details.row.value, justifyContent: 'center' }} />
            <Text style={{
              ...styles.invoice_billing_details.row.value, ...styles.invoice_billing_details.total_row.value,
              justifyContent: 'flex-end', ...(status?.id !== 1 ? { ...styles.invoice_billing_details.row.value.due } : {})
            }}>
              ${acc.formatNumber(total / 100, 2, ' ', ',')}
            </Text>
          </View>
        </View>
        <View style={{ ...styles.invoice_footer, ...(status?.id !== 1 ? {...styles.invoice_footer.due} : {}) }}>
          <Text style={{ ...styles.invoice_footer.text, ...(status?.id !== 1 ? {...styles.invoice_footer.text.due} : {}) }}>
            Questions about your bill? Contact us at:
            <Link src='mailto:billing@dropship.com' style={{...styles.invoice_footer.text.link, ...(status?.id !== 1 ? {...styles.invoice_footer.text.link.due} : {})}}> billing@dropship.com</Link>
          </Text>
          <View style={{ ...styles.invoice_footer.actions }}>
            <Image style={{...styles.invoice_footer.image}} source={status?.id !== 1 ? heartRed : heartGreen} />
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default InvoicePDF;
