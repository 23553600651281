import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import Icon from '../../../Icon';
import { Input } from 'antd';
import PresetsModal from '../../ShopifySearch/components/PresetsModal';
import PresetsDropdown from './PresetsDropdown';
import SortDropdown from './SortDropdown';
import SortModal from './SortModal';
import './PageOptions.less';

const { Search } = Input;

const PageOptions = (
  {
    setShowFilters,
    showFilters,
    setModal,
    isChanged,
    setInputText,
    inputText,
    skeleton,
    isMobile,
    sortOrder,
    setSortOrder,
    setAppliedFilters,
    disabled,
    adsLoading
  },
) => {

  const { t } = useTranslation();

  const [visibleModal, setVisibleModal] = useState(null);

  const handleSearch = (e) => {
    setInputText(e.target.value.trim());
  };

  const handleSubmitSearch = () => {
    setAppliedFilters({"global_search": inputText})
  };

  const handleSort = (order) => {
    setSortOrder(order);
  }

  if (skeleton) return (
    <div className={'page-options-wrapper adlibrary'}>
      <div className="search-options">
        <div className="search">
          <span className={'link skeleton'}
                style={{ width: 400, height: 40 }}
          />
          <span className={'link skeleton'}
                style={{ width: 70, height: 40 }}
          />
        </div>
        <span className={'link skeleton'}
              style={{ width: 92, height: 40 }}
        />
      </div>
      <div className={'view-options'}>
        <span className={'link skeleton'}
              style={{ width: '50%', height: 40 }}
        />
        <span className={'link skeleton'}
              style={{ width: '50%', height: 40 }}
        />
      </div>
    </div>
  );

  return (
    <div className={'page-options-wrapper adlibrary'}>
      <div className="search-options">
        <div className="search">
          <Search placeholder={t('Search for ads...')}
                  className={'search-input'}
                  onChange={(e) => {
                    e.persist();
                    handleSearch(e);
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13 && inputText && !adsLoading) handleSubmitSearch()
                  }}
                  defaultValue={inputText && inputText}
                  {...(inputText ? { value: inputText } : null)}
                  prefix={<Icon type="search_icon"
                                role="icon"
                  />}
          />
          <div className={cls('search-submit', {
            'disabled': !inputText || adsLoading,
          })}
               onClick={() => inputText && !adsLoading && handleSubmitSearch()}
          >
            {t('Search')}
          </div>
        </div>
        {
          isMobile ?
            null
            :
            <span className={cls('filters', {
              active: showFilters,
              disabled: disabled,
            })}
                  onClick={() => {
                    if (inputText) setInputText('');
                    else setShowFilters(!showFilters);
                  }}
            >
              {
                inputText ?
                  null
                  :
                  <Icon type={'filter_icon'} role={'icon'} />
              }
              {
                inputText ?
                  t('Reset search')
                  :
                  t('Filters')
              }
            </span>
        }
      </div>
      <div className={'view-options'}>
        {
          isMobile ?
            <span className={cls('filters', {
              active: showFilters,
              disabled: disabled,
            })}
                  onClick={() => {
                    if (inputText) setInputText('');
                    else setShowFilters(!showFilters);
                  }}
            >
              {
                inputText ?
                  null
                  :
                  <Icon type={'filter_icon'} role={'icon'} />
              }
              {
                inputText ?
                  t('Reset search')
                  :
                  t('Filters')
              }
            </span>
            :
            null
        }
        {
          !isMobile ?
            <PresetsDropdown isMobile={isMobile}
                             setModal={setModal}
                             isChanged={isChanged}
                             setVisibleModal={setVisibleModal}
                             disabled={disabled}
            />
            :
            null
        }
        <SortDropdown order={sortOrder}
                      setSort={handleSort}
                      isMobile={isMobile}
                      setVisibleModal={setVisibleModal}
        />
      </div>
      <PresetsModal setModal={setModal}
                    setVisibleModal={setVisibleModal}
                    isMobile={isMobile}
                    visible={visibleModal === 'presets_modal'}
                    isChanged={isChanged}
      />
      <SortModal order={sortOrder}
                 setSort={handleSort}
                 setModal={setVisibleModal}
                 visible={visibleModal === 'sort_modal'}
                 isMobile={isMobile}
      />
    </div>
  );
};

export default PageOptions;
