import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { Carousel } from 'antd';
import cls from 'classname';
import Icon from '../../../Icon';
import utils from '../../../Utils/utils';
import '../../AdSpotPage/components/AdItem.less';


export const FadspotComponent = ({ data, visibleTooltipAction, visibleTooltipHeadline, setAdLibrarySlide }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [playing, setPlaying] = useState(false);
  const carouselRef = useRef(null);


  const renderCarousel = (cards) => {
    return (
      <Carousel ref={carouselRef} beforeChange={(current, next) => {
        setCurrentSlide(next)
        if(setAdLibrarySlide){
          setCurrentSlide(next)
        }
      }} infinite={false}>
        {cards.map((card, index) => (
          <div key={card?.thumbnail + index} style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="fadspot-page-item-image-wrapper">
              {card.creative_type === 'video' ? (
                playing && currentSlide === index ? (
                  <ReactPlayer controls={true} width={'100%'} height={'100%'} playing={true} url={card?.video_url} />
                ) : (
                  <img src={card?.video_preview_url} alt="" onError={utils.addDefaultSrc} />
                )
              ) : (
                <img src={card?.image_url} alt="" onError={utils.addDefaultSrc} />
              )}
              {card.creative_type === 'video' && !playing && (
                <div className="fadspot-page-item-image-play" onClick={() => setPlaying((prev) => !prev)}>
                  <Icon type={'ad_spot_play'} role={'button'} />
                </div>
              )}
            </div>
          </div>
        ))}
      </Carousel>
    );
  };

  const renderCreative = (creativeType, data) => {
    switch (creativeType) {
      case 'carousel':
        return renderCarousel(data?.snapshot?.cards || []);
      case 'image':
        return (
          <div className="fadspot-page-item-image-wrapper">
            <img src={data?.snapshot?.images} alt="" onError={utils.addDefaultSrc} />
          </div>
        );
      case 'video':
        return (
          <div className="fadspot-page-item-image-wrapper">
            {playing ? (
              <ReactPlayer controls={true} width={'100%'} height={'100%'} playing={true} url={data?.snapshot?.videos?.video_url} />
            ) : (
              <img src={data?.snapshot?.videos.preview_url} alt="" onError={utils.addDefaultSrc} />
            )}
            {!playing && (
              <div className="fadspot-page-item-image-play" onClick={() => setPlaying((prev) => !prev)}>
                <Icon type={'ad_spot_play'} role={'button'} />
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  const renderTitle = () => {
    const cards = data?.snapshot?.cards;
    if (cards && cards.length > 0) {
      return cards[currentSlide]?.caption || '';
    }
    return data?.snapshot?.caption || '';
  };
  const renderSubTitle = () => {
    const cards = data?.snapshot?.cards;
    if (cards && cards.length > 0) {
      return cards[currentSlide]?.title || '';
    }
    return data?.snapshot?.title || '';
  };
  const renderDescription = () => {
    const cards = data?.snapshot?.cards;
    if (cards && cards.length > 0) {
      return cards[currentSlide]?.link_description || '';
    }
    return data?.snapshot?.link_description || '';
  };


  const renderButton = () => {
    const cards = data?.snapshot?.cards;
    if (cards && cards.length > 0) {
      const currentCard = cards[currentSlide];
      return (
        <div
          className="fadspot-page-item-action-btn"
          onClick={() => window.open(currentCard?.link_url, '_blank')}
        >
          {currentCard?.cta_text || ''}
        </div>
      );
    }
    return (
      <div
        className="fadspot-page-item-action-btn"
        onClick={() => window.open(data?.snapshot?.link_url, '_blank')}
      >
        {data?.snapshot?.cta_text || ''}
      </div>
    );
  };

  return (
    <div className="fadspot-page-item-action-wrapper">
      {Object.keys(data?.snapshot?.cards || {})?.length > 1 ? <div className='fadspot-page-item-action-slider'>
        <div
          className={cls('fadspot-page-item-action-slider-prev', { disabled: currentSlide === 0 })}
          style={{ display: visibleTooltipAction || visibleTooltipHeadline ? 'none' : 'flex' }}
          onClick={() => {
            carouselRef.current.prev();
            setPlaying(false);
          }}
        >
          <Icon type={'arrow_slider_prev'} role={'icon'} />
        </div>
        <div className="fadspot-page-item-action-slider-counter">
          <Icon type={'pictures'} role={'icon'} />
          {currentSlide + 1}/{[...Object.values(data?.snapshot?.cards)]?.length}
        </div>
        <div
          className={cls('fadspot-page-item-action-slider-next', {
            disabled: currentSlide === [...Object.values(data?.snapshot?.cards)]?.length - 1
          })}
          style={{ display: visibleTooltipAction || visibleTooltipHeadline ? 'none' : 'flex' }}
          onClick={() => {
            carouselRef.current.next();
            setPlaying(false);
          }}
        >
          <Icon type={'arrow_slider_next'} role={'icon'} />
        </div>
      </div> : null}
      {renderCreative(data?.creative_type, data)}
      <div className="fadspot-page-item-action"
        style={{ position: visibleTooltipAction || visibleTooltipHeadline ? 'relative' : 'static' }}
      >
        <div className="fadspot-page-item-action-text">
              <span className="addLibrary-page-item-action-title">
                {renderTitle()}
              </span>
          <span className="addLibrary-page-item-action-sub-title">
            {renderSubTitle()}
          </span>
          <span className="addLibrary-page-item-action-description">
            {renderDescription()}
          </span>
        </div>
        {renderButton()}
      </div>
    </div>
  );
};