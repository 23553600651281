import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BreadcrumbsActions from '../Breadcrumbs/actions';
import Icon from '../../Icon';
import { ButtonComponent } from '../../Components/Button';
import Collections from './components/collections';
import Products from './components/productList';
import CollectionsSkeletons from './components/skeleton/CollectionsSkeletons';
import Creators from './reducer';
import { EventHandler } from '../../Utils/event-handler';
import TopPageHeader from '../SalesTrackerPage/pages/components/TopPageHeader';
import cls from 'classname';
import { Input } from 'antd';
import DeleteModal from '../CollectionsModals/components/modals/delete-modal';
import RenameModal from '../CollectionsModals/components/modals/rename-modal';
import OptionsModal from '../CollectionsModals/components/modals/OptionsModal';
import './styles.less';

const { Search } = Input;

const CollectionsPage = (
  {
    loading,
    fetching,
    products,
    collections,
    collection,
    theme,
    isMobile,
    dispatch,
  }) => {

  const { t } = useTranslation();
  const [searchText, setSearch] = useState('');
  const [view, setView] = useState('collections');
  const [modal, setModal] = useState(null);


  // BREADCRUMBS
  useEffect(() => {
    document.title = 'Collections - Dropship';
    dispatch(BreadcrumbsActions.clearBreadCrumbs());
    dispatch(
      BreadcrumbsActions.addBreadCrumb({
        name: 'Collections',
        link: '/collections',
      }),
    );
    dispatch(Creators.clearProductData());
    dispatch(Creators.getCollectionsRequest({ page_size: 40 }));
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  // CREATE COLLECTION
  const onCreateCollections = () => {
    EventHandler.emit('open-create-modal');
  };

  // GET NEXT PRODUCTS ACTION HANDLER
  const getNextProducts = () => {
    if (products?.next) {
      dispatch(
        Creators.getProductsNextRequest({
          link: products.next,
        }),
      );
    }
  };

  // GET NEXT COLLECTIONS ACTION HANDLER
  const getNextCollections = () => {
    if (collections?.next) {
      dispatch(
        Creators.getNextCollectionsRequest({
          link: collections.next,
        }),
      );
    }
  };

  // SEARCH HANDLER
  const handleSearch = (e) => {
    setSearch(e.target.value.trim());
    if (!e.target.value) {
      setView('collections');
      dispatch(Creators.getProductsRequest({
          q: '',
          saved: true,
          page_size: 40,
        }),
      );
    }
  };

  const handleSubmitSearch = () => {
    setView('products');
    dispatch(Creators.getProductsRequest({
        q: searchText,
        saved: true,
        page_size: 40,
      }),
    );
  };

  // ADD/REMOVE ACTION HANDLER
  const addToCollectionFunc = ({ id, isSave, name }) => {
    if (!isSave) {
      dispatch(
        Creators.addToCollectionRequest({
          productID: id,
          productName: name,
        }),
      );
    } else {
      dispatch(
        Creators.removeFromCollectionRequest({
          productID: id,
          productName: name,
        }),
      );
    }
  };

  if (fetching) return <CollectionsSkeletons isMobile={isMobile} />;

  return (
    <div className={'product-database-page collections-page'}>
      <TopPageHeader icon={'collections_dashboard'}
                     title={'Collections'}
                     subtitle={'Your saved products from portfolio'}
      />
      <div className={'collections-page-options-wrapper'}>
        <div className={'search-wrapper'}>
          <Search placeholder={t('Search for products...')}
                  className={'search-input'}
                  onChange={(e) => {
                    e.persist();
                    handleSearch(e);
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13 && searchText && !loading) handleSubmitSearch()
                  }}
                  defaultValue={searchText && searchText}
                  {...(searchText ? { value: searchText } : null)}
                  prefix={<Icon type="search_icon"
                                role="icon"
                  />}
          />
          <div className={cls('search-submit', {
            'disabled': !searchText || loading,
          })}
               onClick={handleSubmitSearch}
          >
            {t('Search')}
          </div>
        </div>
        <ButtonComponent className="create-collection"
                         onClick={onCreateCollections}
        >
          <Icon type="plus" role="icon" />
          {t('Create collection')}
        </ButtonComponent>
      </div>
      {
        view === 'products' ?
          (
            <Products products={(products.results && products.results) || []}
                      loading={loading}
                      theme={theme}
                      getNextList={getNextProducts}
                      next={products?.next}
                      searchText={searchText}
                      isMobile={isMobile}
                      setInputText={setSearch}
                      setView={setView}
                      addToCollectionFunc={(id, isSave, name) =>
                        addToCollectionFunc({ id, isSave, name })
                      }
            />
          )
          :
          (
            <Collections collections={collections.results}
                         loading={loading}
                         next={collections?.next}
                         getNextCollections={getNextCollections}
                         dispatch={dispatch}
                         setModal={setModal}
            />
          )
      }
      <OptionsModal setModal={setModal}
                    isMobile={isMobile}
                    modal={modal}
      />
      <DeleteModal setModal={setModal}
                   isMobile={isMobile}
                   modal={modal}
                   loading={loading}
                   removeCollection={(id) => dispatch(Creators.removeCollectionRequest({ collectionID: id }))}
                   collection={collection}
      />
      <RenameModal setModal={setModal}
                   isMobile={isMobile}
                   modal={modal}
                   renameCollection={(id, name) => dispatch(Creators.renameCollectionRequest({
                     collectionID: id,
                     collectionName: name,
                   }))}
                   collection={collection}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.collections.loading,
  fetching: state.collections.fetching,
  collections: state.collections.collections,
  collection: state.collections.collection,
  products: state.collections.products,
  theme: state.nav.theme,
  isMobile: state.nav.isMobile,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionsPage);
