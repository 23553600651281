import React from 'react';
import Icon from "../../../Icon";
import {useTranslation} from "react-i18next";
import './SalesTrackerNote.less'

const SalesTrackerNote = () => {
  const { t } = useTranslation();

  return (
    <div className='sales-tracker-note-wrapper'>
      <p className='sales-tracker-note-title'>
        {/* <Icon type={'attention_outline'} role={'icon'} width={24} height={24} color={'#707ba0'}/> */}
        <span>{t('Sales data are estimates and may contain error margins.')}</span>
      </p>
      <p className='sales-tracker-note-description'>
        {t('Error margins increase for products with lower sales volume and decrease for products with higher sales volume.')}
      </p>
    </div>
  );
};

export default SalesTrackerNote;
