import React, { useState } from 'react';
import Icon from '../../../../Icon';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import HeaderModal from './HeaderModal';
import acc from 'accounting';
import cls from 'classname';

const TopPageHeader = (
  {
    icon,
    title,
    subtitle,
    takeTourHandler = null,
    watchTutorialHandler = null,
    increaseLimitsHandler = null,
    skeleton,
    isMobile,
    modalTitle = '',
    modalText = '',
    withCredits = false,
    withLimits = false,
    isYearly,
    isTrial,
    creditsLeft,
    creditsTotal,
    getClassForCreditsTotal
  }) => {

  const {t} = useTranslation();

  const [visible, setVisible] = useState(false);
  const [visibleModal, setVisibleModal] = useState(null);

  const handleVisibleChange = (value) => setVisible(value);

  if (skeleton) return (
    <div className="sales-tracker-top-page-header">
      <div className="title-wrapper">
        {
          isMobile ?
            null
            :
            <span className={'link skeleton'}
                  style={{ width: 40, height: 40 }}
            />
        }
        <div className="sales-tracker-top-page-subheader">
            <span className={'link skeleton'}
                  style={{ width: 70, height: 20 }}
            />
          <span className={'link skeleton'}
                style={{ width: 252, height: 20 }}
          />
        </div>
      </div>
      {
        withCredits && !isMobile ?
          <span className={'link skeleton'}
                style={{ width: 245, height: 36, margin: '0 16px 0 auto' }}
          />
          :
          null
      }
      {
        withLimits && !isMobile ?
          <span className={'link skeleton'}
                style={{ width: 345, height: 36, margin: '0 16px 0 auto' }}
          />
          :
          null
      }
      <span className={'link skeleton'}
            style={{ width: 40, height: 10 }}
      />
    </div>
  );

  return (
    <div className="sales-tracker-top-page-header">
      <div className="title-wrapper">
        <div className="title-wrapper-icon">
          <Icon type={icon} role={'icon'} />
        </div>
        <div className="sales-tracker-top-page-subheader">
          <h3 className="sales-tracker-top-page-title">
            {t(title)}
          </h3>
          <h4 className="sales-tracker-top-page-subtitle">
            {t(subtitle)}
          </h4>
        </div>
      </div>
      {
        withCredits && !isMobile ?
          <div className="sales-tracker-top-page-credits">
            <span className='sales-tracker-top-page-credits-title'>
              {
                t('Search credits')
              }
              <Icon
                type={'attention_outline'}
                role="button"
                width={16}
                height={16}
                tooltipProps={{
                  trigger: 'hover',
                  placement: 'bottom',
                  overlayClassName: 'subscription-tooltip scheduled credit-tooltip',
                  getPopupContainer: (trigger) => trigger.parentNode,
                }}
                titleText={t(isYearly
                  ? 'Each ad seen is 1 credit used. Credits reset every 30 days.'
                  : 'Each ad seen is 1 credit used. Credits reset on your next billing cycle.',)}
              />
            </span>
            <span className="sales-tracker-top-page-credits-value">
            <span
                className={`total-${getClassForCreditsTotal(
                  creditsTotal,
                  creditsLeft,
                )}`}>
                {acc.formatNumber(creditsLeft, 0, ',')}
              </span>
              <span className={'separator'}>
                /
              </span>
              <span className={'maximum'}>
                {acc.formatNumber(creditsTotal, 0, ',')}
              </span>
            </span>
          </div>
          :
          null
      }
      {
        withLimits && !isMobile ?
          <div className="sales-tracker-top-page-credits">
            <span className="sales-tracker-top-page-credits-title">
              {
                t('Tracking limit')
              }
            </span>
            <span className="sales-tracker-top-page-credits-value right-border">
              <span className={'current total-orange'}>
                {acc.formatNumber(withLimits?.stores?.used || 0, 0, ',')}
              </span>
              <span className={'separator'}>
                /
              </span>
              <span className={'maximum'}>
                {acc.formatNumber(withLimits?.stores?.allowed || 0, 0, ',')}
              </span>
              <span className={'type'}>
                {t('stores')}
              </span>
            </span>
            <span className="sales-tracker-top-page-credits-value">
              <span className={'current total-orange'}>
                {acc.formatNumber(withLimits?.products?.used || 0, 0, ',')}
              </span>
              <span className={'separator'}>
                /
              </span>
              <span className={'maximum'}>
                {acc.formatNumber(withLimits?.products?.allowed || 0, 0, ',')}
              </span>
              <span className={'type'}>
                {t('products')}
              </span>
            </span>
          </div>
          :
          null
      }
      {
        takeTourHandler || watchTutorialHandler || increaseLimitsHandler ?
          isMobile ?
            <span className={'sales-tracker-top-page-header-btn'}
                  onClick={() => {
                    setVisibleModal('header_modal');
                  }}
            >
              <Icon type={'three_dots'} role={'icon'} />
            </span>
            :
            <Dropdown destroyPopupOnHide={true}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onOpenChange={handleVisibleChange}
                      placement="bottomRight"
                      dropdownRender={() => (
                        <div className={'sales-tracker-top-page-header-dropdown'}>
                          {
                            takeTourHandler ?
                              <div className="sales-tracker-top-page-header-dropdown-option tour"
                                   onClick={() => {
                                     takeTourHandler();
                                     setVisible(false);
                                   }}
                              >
                                <Icon type="take_tour" role="icon" />
                                <span>
                                {t('Take tour')}
                              </span>
                              </div>
                              :
                              null
                          }
                          {
                            watchTutorialHandler ?
                              <div className="sales-tracker-top-page-header-dropdown-option tutorial"
                                   onClick={() => {
                                     watchTutorialHandler();
                                     setVisible(false);
                                   }}
                              >
                                <Icon type={'watch_tutorial'}
                                      role={'icon'}
                                />
                                <span>
                                {t('Watch tutorial')}
                              </span>
                              </div>
                              :
                              null
                          }
                          {
                            increaseLimitsHandler ?
                              <div className={cls('sales-tracker-top-page-header-dropdown-option tutorial', {
                                disabled: isTrial,
                              })}
                                   onClick={() => {
                                     if (isTrial) return;
                                     increaseLimitsHandler();
                                     setVisible(false);
                                   }}
                              >
                                <Icon type={'speedometer'}
                                      role={'icon'}
                                />
                                <span>
                                {t('Increase limits')}
                              </span>
                              </div>
                              :
                              null
                          }
                        </div>
                      )}
                      trigger={['click']}
                      open={visible}
            >
            <span className={'sales-tracker-top-page-header-btn'}>
              <Icon type={'three_dots'} role={'icon'} />
            </span>
            </Dropdown>
          :
          null
      }
      <HeaderModal  setModal={setVisibleModal}
                    isMobile={isMobile}
                    visible={visibleModal}
                    takeTourHandler={takeTourHandler}
                    watchTutorialHandler={watchTutorialHandler}
                    increaseLimitsHandler={increaseLimitsHandler}
                    title={modalTitle}
                    text={modalText}
                    isTrial={isTrial}
      />
    </div>
  );
};

export default TopPageHeader;
