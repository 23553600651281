import React, { useState, useRef } from 'react';
import { Col, Divider, Row, Slider, Tooltip } from 'antd';
import Icon from '../../../Icon';
import InputFilter from './InputFilter';
import cls from 'classname';
import DropdownBlockTest from '../../ProductDatabasePage/components/DropdownBlockTest';
import DropdownDateBlock from '../../ProductDatabasePage/components/DropdownDateBlock';
import ButtonComponent from '../../../Components/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import acc from 'accounting';

const dateOptions = [
  { id: 0, name: 'Last 7 days' },
  { id: 1, name: 'Last 30 days' },
  { id: 2, name: 'Last 90 days' },
  { id: 3, name: 'Last 6 months' },
  { id: 4, name: 'Last 12 months' },
];

const inputsData = [
  {
    key: 'ad_copy',
    title: 'Ad copy',
    tooltip:
      'Search for text that is included in the caption, headline, or description of the ad.',
  },
  {
    key: 'page_name',
    title: 'Page name',
    tooltip:
      'Search for a specific Facebook page or pages you are looking for.',
  },
  {
    key: 'domain_name',
    title: 'Domain name',
    tooltip: 'Search for specific domain names you are looking to see ads for.',
  },
];

const creativeData = [
  {
    key: 'all',
    title: 'All',
    icon: null,
  },
  {
    key: 'video',
    title: 'Video',
    icon: 'ad_spot_video',
  },
  {
    key: 'image',
    title: 'Photo',
    icon: 'ad_spot_photo',
  },
  {
    key: 'carousel',
    title: 'Carousel',
    icon: 'ad_spot_carousel',
  },
];

const engagementData = [
  {
    key: 'reactions',
    title: 'Reactions',
  },
  {
    key: 'shares',
    title: 'Shares',
  },
  {
    key: 'comments',
    title: 'Comments',
  },
  {
    key: 'video_length',
    title: 'Video length',
  },
];

const FiltersBlock = ({
  isMobile,
  pageSize,
  pageNumber,
  setPageSize,
  setPageNumber,
  setSortOrder,
  inputFilters,
  setInputFilters,
  creativeFilters,
  setCreativeFilters,
  engagementFilters,
  setEngagementFilters,
  dropdownFilters,
  setDropdownFilters,
  setDefaultSearch,
  disabledSearch,
  setLoadedPreset,
  skeleton,
  adsLoading,
  suggestions,
  suggestionsLoading,
  getSuggestions,
  resetSuggestions,
  inputFiltersSuggestions,
  setInputFiltersSuggestions,
  applyFilters,
}) => {
  const { t } = useTranslation();
  const rerenderKey = useRef(new Date().getTime());
  const [visibleModal, setVisibleModal] = useState(null);

  const languageOptions =
    useSelector((store) => store.adSpot.filters.languages) || [];
  const actionOptions =
    useSelector((store) => store.adSpot.filters.call_to_action) || [];
  const websiteOptions =
    useSelector((store) => store.adSpot.filters.website_providers) || [];
  const domainOptions =
    useSelector((store) => store.adSpot.filters.domain_tld) || [];

  const sliderTooltipFormatter = (key, value) => {
    if (key === 'video_length')
      return `${
        Math.floor(value / 60) < 10
          ? '0' + Math.floor(value / 60)
          : Math.floor(value / 60)
      }:${
        value - Math.floor(value / 60) * 60 < 10
          ? '0' + (value - Math.floor(value / 60) * 60)
          : value - Math.floor(value / 60) * 60
      }`;
    else return `${acc.formatNumber(value, 0, ',', '')}`;
  };

  const videoLengthFormat = (min, max) => {
    const info = (num) => ({
      minutes: Math.floor(num / 60),
      seconds: num - Math.floor(num / 60) * 60,
    });
    const minInfo = info(min);
    const maxInfo = info(max);
    return `${
      minInfo?.minutes < 10 ? '0' + minInfo?.minutes : minInfo?.minutes
    }:${minInfo?.seconds < 10 ? '0' + minInfo?.seconds : minInfo?.seconds} - ${
      maxInfo?.minutes < 10 ? '0' + maxInfo?.minutes : maxInfo?.minutes
    }:${maxInfo?.seconds < 10 ? '0' + maxInfo?.seconds : maxInfo?.seconds}`;
  };

  const onChangeInputFilter = (e, key, skip = false) => {
    e.persist();
    if (!skip)
      setInputFilters((state) => ({
        ...state,
        [key]:
          state[key] === null
            ? e.target.value.split(',')[0].trim() + ','
            : state[key] + ',' + e.target.value.split(',')[0].trim(),
      }));
  };

  const onPasteInputFilter = (e, key) => {
    e.persist();
    let val = e.clipboardData.getData('text').replaceAll(',', '');
    setInputFilters((state) => ({
      ...state,
      [key]:
        state[key] === null
          ? val.trim() + ','
          : [...new Set([...state[key].split(','), val?.trim()])].join(','),
    }));
  };

  const resetFilters = () => {
    rerenderKey.current = new Date().getTime();
    Object.keys(inputFilters).forEach((key) =>
      setInputFilters((state) => ({ ...state, [key]: null })),
    );
    Object.keys(inputFiltersSuggestions).forEach((key) =>
      setInputFiltersSuggestions((state) => ({ ...state, [key]: [] })),
    );
    Object.keys(engagementFilters).forEach((key) =>
      setEngagementFilters((state) => ({
        ...state,
        [key]: { min: 0, max: key === 'video_length' ? 600 : 10000 },
      })),
    );
    Object.keys(dropdownFilters).forEach((key) => {
      if (key === 'ad_created_at') {
        setDropdownFilters((state) => ({
          ...state,
          [key]: { min: null, max: null, id: null },
        }));
      } else setDropdownFilters((state) => ({ ...state, [key]: 'All' }));
    });
    setCreativeFilters('all');
    setPageSize(50);
    setPageNumber(1);
    setSortOrder('most_recent');
    setLoadedPreset(null);
  };

  const handleSearch = (new_search = false) => {
    if (pageSize === 50 && pageNumber === 1) applyFilters(new_search);
    else {
      setPageSize(50);
      setPageNumber(1);
      setDefaultSearch(false);
    }
  };

  if (skeleton)
    return (
      <div className="fadspot-page-filters-wrapper">
        <Row
          className="fadspot-page-filters-main-block-wrapper"
          gutter={[48, 0]}
        >
          <Col xs={24} md={12} style={{ paddingRight: isMobile ? 24 : 32 }}>
            <div className="fadspot-page-filters-inputs input-filter-wrapper">
              <div style={{ display: 'flex', marginBottom: 24 }}>
                <span
                  className="link skeleton"
                  style={{ width: 50, height: 20 }}
                />
              </div>
              {[150, 196, 171].map((el) => (
                <div key={el} className="input-filter-skeleton-wrapper">
                  <span
                    className="link skeleton"
                    style={{ width: el, height: 20 }}
                  />
                  <div>
                    <span
                      className="link skeleton"
                      style={{ width: 184, height: 20 }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </Col>

          <Col
            xs={24}
            md={12}
            style={{
              paddingLeft: isMobile ? 24 : 12,
              marginTop: isMobile ? 12 : 0,
            }}
          >
            <div className="fadspot-page-filters-options">
              <div style={{ display: 'flex', marginBottom: 24 }}>
                <span
                  className="link skeleton"
                  style={{ width: 50, height: 20 }}
                />
              </div>
              <div
                className="fadspot-page-filters-options-creative-wrapper"
                style={{ marginTop: 8 }}
              >
                {[1, 2, 3, 4].map((el) => (
                  <span
                    key={el}
                    className="link skeleton"
                    style={{
                      width: isMobile ? '100%' : 88,
                      height: 40,
                      margin: '0 5px',
                    }}
                  />
                ))}
              </div>
              <div style={{ display: 'flex', marginBottom: 24 }}>
                <span
                  className="link skeleton"
                  style={{ width: 110, height: 20 }}
                />
              </div>
              <div
                className="fadspot-page-filters-options-engagement-wrapper"
                style={{ marginTop: 12 }}
              >
                {[1, 2, 3, 4].map((el) => (
                  <div
                    key={el * el}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: isMobile ? '100%' : 249,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: isMobile ? '100%' : 249,
                      }}
                    >
                      <span
                        className="link skeleton"
                        style={{ width: 86, height: 20 }}
                      />
                    </div>
                    <span
                      className="link skeleton"
                      style={{
                        width: isMobile ? '100%' : 249,
                        height: 20,
                        marginTop: 14,
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>

        <Col
          span={24}
          className="selects-wrapper fadspot-page-filters-dropdowns"
        >
          {[1, 2, 3, 4, 5].map((el) => (
            <div
              key={el / 2}
              className={'select-block'}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <span
                className="link skeleton"
                style={{ width: 114, height: 20 }}
              />
              <span
                className="link skeleton"
                style={{ width: '100%', height: 40, marginTop: 8 }}
              />
            </div>
          ))}
        </Col>

        <Divider style={{ margin: '30px auto 24px' }} type={'horizontal'} />

        <div className="fadspot-page-filters-button-wrapper">
          <span className="link skeleton" style={{ width: 116, height: 40 }} />
          <span
            className="link skeleton"
            style={{ width: 87, height: 40, marginLeft: 12 }}
          />
        </div>
      </div>
    );

  const filterType = () => {
    return (
      <>
        {isMobile ? <Divider type={'horizontal'} /> : null}
        <h5 className="fadspot-page-filters-inputs-header">{t('Type')}</h5>
        <div className="fadspot-page-filters-options-creative-wrapper">
          {creativeData.map((el) => (
            <div
              key={el?.key}
              className={cls('fadspot-page-filters-options-creative-button', {
                active: creativeFilters.includes(el?.key),
              })}
              onClick={() => {
                if (el?.key === 'all') {
                  setCreativeFilters(el?.key);
                } else {
                  if (creativeFilters.includes(el?.key)) {
                    if (creativeFilters.length === 1) setCreativeFilters('all');
                    else {
                      if (el?.key === 'video') {
                        setEngagementFilters((prev) => ({
                          ...prev,
                          video_length: { min: 0, max: 600 },
                        }));
                      }
                      setCreativeFilters((state) => [
                        ...state.filter((elem) => elem !== el?.key),
                      ]);
                    }
                  } else {
                    if (creativeFilters.length === 2) {
                      setCreativeFilters('all');
                    } else {
                      if (
                        el?.key !== 'video' &&
                        !creativeFilters.includes('video')
                      ) {
                        setEngagementFilters((prev) => ({
                          ...prev,
                          video_length: { min: 0, max: 600 },
                        }));
                      }
                      setCreativeFilters((state) =>
                        typeof state === 'string'
                          ? [el?.key]
                          : [...state, el?.key],
                      );
                    }
                  }
                }
              }}
            >
              {!isMobile
                ? el?.icon && <Icon role={'icon'} type={el?.icon} />
                : null}
              {t(el?.title)}
            </div>
          ))}
        </div>
        {isMobile ? (
          <Divider style={{ marginBottom: 0 }} type={'horizontal'} />
        ) : null}
      </>
    );
  };

  return (
    <div className="fadspot-page-filters-wrapper ad-spot-filters-wrapper">
      <div className="fadspot-page-filters">
        <Row
          className="fadspot-page-filters-main-block-wrapper"
          gutter={[48, 0]}
        >
          <Col xs={24} md={12} style={{ paddingRight: isMobile ? 24 : 32 }}>
            <div className="fadspot-page-filters-inputs input-filter-wrapper">
              <div className="fadspot-page-filters-inputs-header">
                {t('Filters')}
              </div>
              {inputsData.map((el) => (
                <InputFilter
                  key={rerenderKey.current + el?.key}
                  el={el}
                  onChangeInputFilter={onChangeInputFilter}
                  onPasteInputFilter={onPasteInputFilter}
                  inputFilters={inputFilters}
                  setInputFilters={setInputFilters}
                  isMobile={isMobile}
                  type={el?.key === 'ad_copy' ? 'static' : 'dynamic'}
                  suggestions={suggestions}
                  suggestionsLoading={suggestionsLoading}
                  getSuggestions={getSuggestions}
                  resetSuggestions={resetSuggestions}
                  setInputFiltersSuggestions={setInputFiltersSuggestions}
                />
              ))}
            </div>
          </Col>
          <Col
            xs={24}
            md={12}
            style={{
              paddingLeft: isMobile ? 24 : 12,
              marginTop: isMobile ? 12 : 0,
            }}
          >
            <div className="fadspot-page-filters-options">
              {!isMobile ? filterType() : null}
              <h5 className="fadspot-page-filters-inputs-header">
                {t('Engagement')}
              </h5>
              <div className="fadspot-page-filters-options-engagement-wrapper">
                {engagementData.map((el) => (
                  <div
                    key={el?.key}
                    className={cls(
                      'fadspot-page-filters-options-engagement-filter',
                      {
                        disabled:
                          el?.key === 'video_length' &&
                          !(
                            creativeFilters.includes('video') ||
                            creativeFilters.includes('all')
                          ),
                      },
                    )}
                  >
                    <div className="fadspot-page-filters-options-engagement-filter-title-wrapper">
                      <span className="fadspot-page-filters-options-engagement-filter-title">
                        {t(el?.title)}
                      </span>
                      <div className={cls('fadspot-page-filters-title-wrapper-item', {
                        "video": el?.key === 'video_length'
                      })}>
                        {el?.key === 'video_length' ? (
                          <span className="fadspot-page-filters-options-engagement-filter-range">
                            {
                              videoLengthFormat(
                                engagementFilters?.[el?.key]?.min,
                                engagementFilters?.[el?.key]?.max,
                              ).split(' - ')[0]
                            }
                          </span>
                        ) : (
                          <span className="fadspot-page-filters-options-engagement-filter-range">
                            {acc.formatNumber(
                              engagementFilters?.[el?.key]?.min,
                              0,
                              ',',
                              '',
                            ) || 0}
                          </span>
                        )}
                        <div className="fadspot-page-filters-title-wrapper-slider">
                          <Slider
                            defaultValue={
                              el?.key === 'video_length' ? [0, 600] : [0, 10000]
                            }
                            value={[
                              engagementFilters?.[el?.key]?.min,
                              engagementFilters?.[el?.key]?.max,
                            ]}
                            range={{ draggableTrack: true }}
                            min={0}
                            max={el?.key === 'video_length' ? 600 : 10000}
                            tooltip={{
                              formatter: (value) =>
                                sliderTooltipFormatter(el?.key, value),
                              ...(isMobile && { open: false }),
                            }}
                            onChange={(value) => {
                              if (
                                !(
                                  el?.key === 'video_length' &&
                                  !(
                                    creativeFilters.includes('video') ||
                                    creativeFilters.includes('all')
                                  )
                                )
                              )
                                setEngagementFilters((prev) => ({
                                  ...prev,
                                  [el?.key]: { min: value[0], max: value[1] },
                                }));
                            }}
                          />
                        </div>
                        {el?.key === 'video_length' ? (
                          <span className="fadspot-page-filters-options-engagement-filter-range">
                            {
                              videoLengthFormat(
                                engagementFilters?.[el?.key]?.min,
                                engagementFilters?.[el?.key]?.max,
                              ).split(' - ')[1]
                            }
                          </span>
                        ) : (
                          <span className="fadspot-page-filters-options-engagement-filter-range">
                            {engagementFilters?.[el?.key]?.max === 10000
                              ? '10k+'
                              : acc.formatNumber(
                                  engagementFilters?.[el?.key]?.max,
                                  0,
                                  ',',
                                  '',
                                )}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {isMobile ? filterType() : null}
            </div>
          </Col>
        </Row>

        <Col
          span={24}
          className="selects-wrapper fadspot-page-filters-dropdowns"
        >
          <DropdownDateBlock
            label={'Ad creation date'}
            id={'ad_created_at'}
            iconType={'ad_spot_filter_calendar'}
            data={dateOptions}
            dropdownFilters={dropdownFilters}
            setDropdownFilters={setDropdownFilters}
            isMobile={isMobile}
            hideTimeZone={true}
            visibleModal={visibleModal}
            setVisibleModal={setVisibleModal}
            withName
          />

          <DropdownBlockTest
            isMobile={isMobile}
            label={'Call to action'}
            id={'call_to_action'}
            dropdownFilters={dropdownFilters}
            setDropdownFilters={setDropdownFilters}
            iconType={'database_action'}
            searchPlaceholder={'Search...'}
            data={actionOptions}
            visibleModal={visibleModal}
            setVisibleModal={setVisibleModal}
          />

          <DropdownBlockTest
            label={'Website provider'}
            id={'website_provider'}
            dropdownFilters={dropdownFilters}
            setDropdownFilters={setDropdownFilters}
            iconType={'database_provider'}
            searchPlaceholder={'Search...'}
            data={websiteOptions}
            isMobile={isMobile}
            visibleModal={visibleModal}
            setVisibleModal={setVisibleModal}
          />

          <DropdownBlockTest
            label={'Ad copy language'}
            id={'ad_copy_language'}
            dropdownFilters={dropdownFilters}
            setDropdownFilters={setDropdownFilters}
            iconType={'ad_spot_filter_globus'}
            searchPlaceholder={'Search...'}
            data={languageOptions}
            isMobile={isMobile}
            visibleModal={visibleModal}
            setVisibleModal={setVisibleModal}
          />

          <DropdownBlockTest
            label={'Domain TLD'}
            id={'domain_tld'}
            dropdownFilters={dropdownFilters}
            setDropdownFilters={setDropdownFilters}
            iconType={'ad_spot_filter_domain'}
            searchPlaceholder={'Search...'}
            data={domainOptions}
            isMobile={isMobile}
            visibleModal={visibleModal}
            setVisibleModal={setVisibleModal}
          />
        </Col>
        {isMobile ? null : (
          <Divider style={{ margin: '30px auto 24px' }} type={'horizontal'} />
        )}
        <div className="fadspot-page-filters-button-wrapper">
          <ButtonComponent
            className={'fadspot-page-filters-button-reset'}
            text={t('Reset filter')}
            onClick={resetFilters}
          />
          {disabledSearch ? (
            <Tooltip
              placement="top"
              title={
                <span>
                  {t('You have ran out of credits. To continue using Ad Spot')},
                  <a href="/setting/plan">{t('get more credits')}</a>
                </span>
              }
              destroyTooltipOnHide={true}
              getPopupContainer={(triggerNode) => triggerNode?.parentNode}
            >
              <div
                className={cls(
                  'fadspot-page-filters-button-apply fadspot-page-filters-button-reset',
                  {
                    disabled: true,
                  },
                )}
              >
                {t('Search')}
              </div>
            </Tooltip>
          ) : (
            <div
              className={cls(
                'fadspot-page-filters-button-apply fadspot-page-filters-button-reset',
                {
                  disabled: adsLoading,
                },
              )}
              onClick={() => handleSearch(true)}
            >
              {t('Search')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FiltersBlock;
