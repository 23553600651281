import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import SalesTrackerCreators from '../reducer';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import { getColumns as getStoresColumns } from './SalesStoreTableColumns';
import { getColumns as getProductsColumns } from './SalesProductsTableColumns';
import { Table } from 'antd';
import UserTrackingsSkeleton from './UserTrackingsSkeleton';
import UserTrackingsEmpty from './UserTrackingsEmpty';
import UserTrackingCard from './UserTrackingCard';

const LocalTable = (props) => <Table {...props} />;

const MemoTable = React.memo(LocalTable, (prev, next) =>
  JSON.stringify(prev?.dataSource) === JSON.stringify(next?.dataSource) && prev?.loading === next?.loading && prev?.sortOrder === next?.sortOrder);

const UserTrackings = (
  {
    view,
    userInfo,
    isMobile,
    salesTracker,
    getStores,
    getStoresNext,
    getProducts,
    getProductsNext,
    setRecordToDelete,
    setModal,
    skeleton,
    displayFormat,
  },
) => {

  const tableRef = useRef(null);
  const { t } = useTranslation();

  const { results, next } = salesTracker?.[view];
  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;

  const [sortOrder, setSortOrder] = useState(null);

  const handleSort = useCallback(({ order, columnKey }) => {
    if (order) {
      const ordering = order === 'ascend' ? columnKey : `-${columnKey}`;
      setSortOrder(ordering);
    } else {
      setSortOrder(null);
    }
  }, []);

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;
    if (currentTopScroll >= (maxTopScroll * 0.95) && !!next) {
      if (view === 'stores') {
        getStoresNext(next);
      } else {
        getProductsNext(next);
      }
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  useEffect(() => {
    const tableContent = tableRef.current?.parentNode || document.getElementById('product-database-table-cards');
    if (tableContent) tableContent.addEventListener('scroll', onScrollHandlerFunc);
    return () => {
      if (tableContent) tableContent.removeEventListener('scroll', onScrollHandlerFunc);
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [next, view, displayFormat]);

  useEffect(() => {
    if (view === 'stores') {
      getStores({ page: 1, page_size: 50 });
    } else {
      getProducts({ page: 1, page_size: 100 });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [view]);

  if (skeleton) return <UserTrackingsSkeleton isMobile={isMobile} />;

  //check if there are no products, to display default banner
  if (!results?.length && !skeleton) return <UserTrackingsEmpty view={view} />;

  return (
    <div className={'sales-tracker-data-wrapper'}>
      <div className={'products-database-table-wrapper sales-tracker-stores-table-wrapper'}>
        {
          displayFormat === 'details' ?
            <div id={'product-database-table-body'}
                 className="product-database-table-body user-trackings-table"
            >
              <MemoTable components={{
                // eslint-disable-next-line react/prop-types
                table: ({ className, children, ...other }) => (
                  <table  {...other}
                          ref={tableRef}
                          className={cls(className, 'other-wrapper')}
                  >
                    {children}
                  </table>
                ),
              }}
                         className="list-table"
                         loading={salesTracker?.fetchingSpin}
                         rowClassName={'product-database-table-row'}
                         rowKey={(record) => record.id}
                         dataSource={
                           results?.length ?
                             [...results]
                             :
                             []
                         }
                         sortOrder={sortOrder}
                         columns={view === 'stores' ?
                           getStoresColumns({
                             sortOrder,
                             setRecordToDelete,
                             setModal,
                             t,
                             isMobile,
                             isTrial,
                           })
                           :
                           getProductsColumns({
                             sortOrder,
                             setRecordToDelete,
                             setModal,
                             t,
                             isMobile,
                             isTrial,
                           })
                         }
                         pagination={false}
                         locale={{ emptyText: '-' }}
                         scroll={{
                           x: 1600, //'max-content',
                           y: 'calc(100dvh - 93px - 91px - 41px)',
                         }}
                         sticky={{
                           offsetHeader: 0,
                           offsetScroll: 0,
                           getContainer: () => document.querySelector('.product-database-table-body'),
                         }}
                         sortDirections={['descend', 'ascend', null]}
                         onChange={(pagination, filters, sorter) => {
                           handleSort(sorter);
                         }}
              />
            </div>
            :
            <div id='product-database-table-cards'
                 className='product-database-table-cards'
            >
              {
                (results?.length ?
                  [...results]
                  :
                  []).map(el => <UserTrackingCard key={el?.id}
                                                  view={view}
                                                  isMobile={isMobile}
                                                  isTrial={isTrial}
                                                  setModal={setModal}
                                                  setRecordToDelete={setRecordToDelete}
                                                  data={el} />)
              }
            </div>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  salesTracker: state.salesTracker,
});

const mapDispatchToProps = (dispatch) => ({
  getStores: (data) => dispatch(SalesTrackerCreators.getTrackingStoresRequest(data)),
  getStoresNext: (data) => dispatch(SalesTrackerCreators.getTrackingStoresNextRequest(data)),
  getProducts: (data) => dispatch(SalesTrackerCreators.getTrackingProductsRequest(data)),
  getProductsNext: (data) => dispatch(SalesTrackerCreators.getTrackingProductsNextRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserTrackings);
