import React, {useEffect, useState} from 'react';
import './SaveFilterPresetBlock.less';
import InputComponent from "../../../Components/Input";
import ButtonComponent from "../../../Components/Button";
import {useDispatch, useSelector} from "react-redux";
import Creators from "../reducer";
import cls from "classname";
import {useTranslation} from "react-i18next";
import { Divider } from 'antd';
import { openNotificationWithIcon } from '../../../Components/Notification';
import { DefaultMsgSaga } from '../../../Components/Notification/notification-message';
import AdLibraryCreators from '../../AdLibraryPage/reducer';

const SaveFilterPresetBlock = (props) => {
  const {setVisible, saveFilterPreset, filters, category} = props;
  const { t } = useTranslation();
  //input value
  const [inputValue, setInputValue] = useState('');
  //if create error
  const saveError = useSelector(store => store[category].createError);
  //show correct name in popup
  const saveResult = useSelector(store => store[category].createResult);
  const dispatch = useDispatch()

  const changeHandler = e => {
    setInputValue(e.target.value)
    dispatch(Creators.resetCreateError())
  }

  //reset create popup, errors on load
  useEffect(() => {
    if (saveResult) {
      setVisible(false)
      if(category === "adLibrary"){
        dispatch(AdLibraryCreators.resetCreateResult())
      }else{
        dispatch(Creators.resetCreateResult())
      }
      
      openNotificationWithIcon({
        style: { minWidth: '400px' },
        className: 'notification notification--save',
        message: (
          <DefaultMsgSaga
            title={t('_name_ preset has been saved', {name: inputValue})}
            icon="notification_success"
            iconOutline={true}
            withTranslate={true}
            preset={inputValue}
          />
        ),
      });
    }
    category === "adLibrary" ? dispatch(AdLibraryCreators.resetCreateError()) :  dispatch(Creators.resetCreateError());
  }, [dispatch, setVisible, saveResult,category])

  return (
    <div className={'load-filter-preset-wrapper save-filter-preset-wrapper'}>
      <div className="load-filter-preset-title">
        {t('Create new preset')}
      </div>
      <p className="load-filter-preset-subtitle">
        {t('Give your preset a name to easily distinguish it from others in the list.')}
      </p>
      <div className="save-filter-preset-input-wrapper" style={{position: 'relative'}}>
        {/*red error text above input*/}
        {saveError && <p
          style={{color: '#D71313', top: -24, fontSize: 12, lineHeight: '16px', position: 'absolute'}}>
          {saveError}
        </p>}
        <InputComponent onChange={changeHandler}
                        className={cls('save-filter-preset-input', {
                          'save-filter-preset-input-error': saveError
                        })}
                        value={inputValue}
                        placeholder={t('Enter name for preset...')}
        />
      </div>
      <Divider type='horizontal' />
      <div className="save-filter-preset-btn-wrapper">
        <ButtonComponent className={'save-filter-preset-cancel'}
                         text={t('Cancel')}
                         onClick={() => {}}
        />
        <ButtonComponent className={'save-filter-preset-submit'}
                         text={t('Create preset')}
                         onClick={() => {
                           saveFilterPreset({name: inputValue.trim(), value: {...filters}})
                         }}
                         disabled={!inputValue}
        />
      </div>
    </div>
  );
};

export default SaveFilterPresetBlock;
