import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import cls from 'classname';
import Creators from './reducer';
import ProductDatabaseCreators from '../ProductDatabasePage/reducer';
import BannerBlock from './components/BannerBlock';
import BannerLimited from '../../Components/Banners/BannerLimited';
import FiltersBlock from './components/FiltersBlock';
import ShopifyStoreCreators from '../ShopifyStore/reducer';
import AutoDSCreators from '../AutoDS/reducer';
import DatabaseProducts from './components/DatabaseProducts';
import openNotification, { openNotificationWithIcon } from '../../Components/Notification/index';
import { DefaultMsg, DefaultMsgSaga } from '../../Components/Notification/notification-message';
import TopPageHeader from '../SalesTrackerPage/pages/components/TopPageHeader';
import PageOptions from './components/PageOptions';
import LoadPresetModal from './components/LoadPresetModal';
import DeletePresetModal from './components/DeletePresetModal';
import SaveFilterPresetModal from './components/SaveFilterPresetModal';
import RenamePresetModal from './components/RenamePresetModal';
import VideoTutorialModal from './components/VideoTutorialModal';
import EmptyTable from './components/EmptyTable';
import DatabaseProductsCards from './components/DatabaseProductsCards';
import LoadPresetOptionsModal from './components/LoadPresetOptionsModal';
import './styles.less';

const CompetitorResearchPage = (
  {
    isAdmin,
    error,
    userInfo,
    isMobile,
    theme,
    productDatabase,
    activeSubscriptions,
    setCompetitor,
    competitor,
    ...props
  }) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentScroll = useRef(null);
  //state to show skeleton
  const [firsLoading, setFirsLoading] = useState(true);
  //check if filters were changed
  const [isChanged, setIsChanged] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [productsAdd, setProductsAdd] = useState(false);
  //toggle table/banner
  const [showTable, setShowTable] = useState(false);
  const [view, setView] = useState('details');
  const [showFilters, setShowFilters] = useState(false);
  const [disableShowFilters, setDisableShowFilters] = useState(false);
  //video modal
  const [modal, setModal] = useState(null);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [switchFilters, setSwitchFilters] = useState({
    exclude_top_brands: false,
    exclude_unavailable: true,
  });
  const [inputFilters, setInputFilters] = useState({
    title: { include: null, exclude: null },
    description: { include: null, exclude: null },
    domain: { include: null, exclude: null },
  });
  const [minMaxFilters, setMinMaxFilters] = useState({
    price: { min: null, max: null },
    sales: { min: null, max: null },
    revenue: { min: null, max: null },
    products: { min: null, max: null },
    images: { min: null, max: null },
    variants: { min: null, max: null },
  });
  const [dropdownFilters, setDropdownFilters] = useState({
    product_created_at: { min: null, max: null, id: null },
    store_created_at: { min: null, max: null, id: null },
    language: 'All',
    currency: 'All',
    domain_tld: 'All',
  });
  const [inputText, setInputText] = useState('');
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortOrder, setSortOrder] = useState(null);
  const [loadedPreset, setLoadedPreset] = useState(null);

  //deleted preset to call undo
  const deleteResult = useSelector(store => store['competitors'].deleteResult);
  const tableData = useSelector(store => store['competitors'].products.results) || [];

  const { fetching, loading, filtersLoading, attemptsLeft, productsLoading } = productDatabase;
  const isVisibleLimitRequest = userInfo?.subscriptions?.[0]?.plan?.plan?.name?.toLowerCase() === 'basic';
  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;

  //show notification after delete to undo
  useEffect(() => {
    if (deleteResult) {
      openNotificationWithIcon({
        deleteResult,
        style: { width: '400px' },
        className: 'notification notification_delete',
        message: (
          <DefaultMsg text={'Preset was successfully removed from your list.'}
                      icon="notification_delete"
                      title={'Preset was successfully removed'}
          />
        ),
      });
      setTimeout(() => dispatch(Creators.resetDeleteResult()), 1);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [dispatch, deleteResult]);

  useEffect(() => {
    document.title = `Competitor Research - Dropship`;
    if (firsLoading) setTimeout(() => setFirsLoading(false), 300);
    if (isAdmin) dispatch(Creators.getShopifyCountRequest());
    props.getFilters();
    props.getPresets();
    if (!isAdmin) {
      props.getShopifyStore();
      props.getAutoStore();
    }
    dispatch(Creators.resetCreateError());
    dispatch(Creators.resetDeleteResult());

    return () => {
      if (competitor) setCompetitor(false);
    };

    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (isMobile) setModal(null);
  }, [isMobile]);

  useEffect(() => {
    if (showTable && !isChanged && !disableShowFilters) setEnabled(false);
  }, [showTable, isChanged, disableShowFilters]);

  useEffect(() => {
    if (attemptsLeft === 0 && (!firsLoading && !loading && !filtersLoading)) {
      openNotification({
        message: t('You have reached your daily search limit, upgrade to unlock unlimited searches'),
        style: { minWidth: '716px' },
        type: 'error',
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [attemptsLeft]);

  useEffect(() => {
    setShowFilters(false);
    setPageSize(50);
    setPageNumber(1);
    setSortOrder(null);
    setProductsAdd(view === 'overview');
  }, [view]);

  useEffect(() => {
    if (inputText) {
      setShowFilters(false);
      setDisableShowFilters(true);
    } else {
      setDisableShowFilters(false)
    }
  }, [inputText]);

  useEffect(() => {
    if (disableShowFilters) {
      resetFilters(false);
    }
  }, [disableShowFilters]);

  const handleChangePageSize = useCallback((value) => {
    setPageSize(value);
  }, []);

  const handleChangePageNumber = useCallback((value) => {
    setPageNumber(value);
  }, []);

  const handleSort = useCallback(({ order, columnKey }) => {
    setPageNumber(1);
    if (order) {
      const ordering = order === 'ascend' ? columnKey : `-${columnKey}`;
      setSortOrder(ordering);
    } else {
      setSortOrder(null);
    }
  }, []);

  const loadPreset = (data) => {
    if (((isTrial || isVisibleLimitRequest) && (attemptsLeft === 0)) || !Boolean(userInfo?.subscriptions?.[0])) {
      openNotification({
        message: t('You have reached your daily search limit, upgrade to unlock unlimited searches'),
        style: { minWidth: '716px' },
        type: 'error',
      });
    } else {
      let name = data.name;
      Object.keys(data.value).forEach(val => {
        if (val === 'switchFilters') setSwitchFilters(state => ({ ...state, ...data.value[val] }));
        if (val === 'inputFilters') setInputFilters(state => ({ ...state, ...data.value[val] }));
        if (val === 'minMaxFilters') setMinMaxFilters(state => ({ ...state, ...data.value[val] }));
        if (val === 'dropdownFilters') setDropdownFilters(state => ({ ...state, ...data.value[val] }));
        if (val === 'global_search') setInputText(data.value[val]);
      });
      openNotificationWithIcon({
        style: { width: '400px' },
        className: 'notification notification--save',
        message: (
          <DefaultMsgSaga text={null}
                          title={t('_name_ preset was applied', {name: name})}
                          icon="notification_success"
                          iconOutline={true}
                          withTranslate={true}
                          preset={name}
          />
        ),
      });
    }
  };

  const resetFilters = (withSearch = true) => {
    Object.keys(switchFilters).forEach((key) => {
      if (key === 'exclude_unavailable') setSwitchFilters(state => ({ ...state, [key]: true }));
      else setSwitchFilters(state => ({ ...state, [key]: false }));
    });
    Object.keys(inputFilters).forEach((key) => setInputFilters(state => ({
      ...state,
      [key]: { include: null, exclude: null },
    })));
    Object.keys(minMaxFilters).forEach((key) => setMinMaxFilters(state => ({
      ...state,
      [key]: { min: null, max: null },
    })));
    Object.keys(dropdownFilters).forEach((key) => {
      if (key === 'product_created_at' || key === 'store_created_at') {
        setDropdownFilters(state => ({ ...state, [key]: { min: null, max: null, id: null } }));
      } else setDropdownFilters(state => ({ ...state, [key]: 'All' }));
    });
    setPageSize(50);
    setPageNumber(1);
    setSortOrder(null);
    setCompetitor(false);
    if (withSearch) {
      setInputText('');
    }
  };

  return (
    <div className={'product-database-page competitors-page'}>
      {
        isVisibleLimitRequest || isTrial ?
          (
            <>
              <TopPageHeader icon={'competitors_dashboard'}
                             title={'Competitors Research'}
                             subtitle={'Search and find all your competitors'}
                             takeTourHandler={() => window.Intercom('startTour', 433090)}
                             watchTutorialHandler={() => setModal('tutorial')}
                             skeleton={firsLoading || filtersLoading}
                             isMobile={isMobile}
              />
              <BannerLimited skeleton={firsLoading || filtersLoading}
                             btnText={t('Upgrade For Unlimited')}
                             onClick={() => navigate('/setting/plan')}
                             className={cls({ 'limited-banner_error': attemptsLeft === 0 })}
                             text={`${t('Daily Searches Remaining')}: ${attemptsLeft}`}
                             isTrial={isTrial}
              />
            </>
          )
          :
          null
      }

      {
        <>
          {
            !isAdmin && !(isTrial || isVisibleLimitRequest) ?
              <TopPageHeader icon={'competitors_dashboard'}
                             title={'Competitors Research'}
                             subtitle={'Search and find all your competitors'}
                             takeTourHandler={() => window.Intercom('startTour', 433090)}
                             watchTutorialHandler={() => setModal('tutorial')}
                             skeleton={firsLoading || filtersLoading}
                             isMobile={isMobile}
                             modalText={'Take a tour through Competitor research to understand how it works better or watch a quick tutorial.'}
                             modalTitle={'Competitors Research'}
              />
              :
              null
          }
          <PageOptions setShowFilters={setShowFilters}
                       showFilters={showFilters}
                       setModal={setModal}
                       view={view}
                       setView={setView}
                       showTable={showTable}
                       isChanged={isChanged}
                       inputText={inputText}
                       setInputText={setInputText}
                       skeleton={firsLoading || filtersLoading}
                       isMobile={isMobile}
                       disableShowFilters={disableShowFilters}
                       disabledSearch={((isTrial || isVisibleLimitRequest) && (attemptsLeft === 0)) || !Boolean(userInfo?.subscriptions?.[0]) || productsLoading}
                       isAdmin={isAdmin}
                       getProducts={props.getProducts}
                       productsAdd={productsAdd}
                       pageNumber={pageNumber}
                       pageSize={pageSize}
                       setPageSize={handleChangePageSize}
                       setPageNumber={handleChangePageNumber}
                       sortOrder={sortOrder}
                       setSortOrder={setSortOrder}
                       setShowTable={setShowTable}
                       setEnabled={setEnabled}
                       currentScroll={currentScroll}
                       setLoadedPreset={setLoadedPreset}
                       loadedPreset={loadedPreset}
          />
          <FiltersBlock isAdmin={isAdmin}
                        resetFilters={resetFilters}
                        loadedPreset={loadedPreset}
                        setLoadedPreset={setLoadedPreset}
                        switchFilters={switchFilters}
                        setSwitchFilters={setSwitchFilters}
                        inputFilters={inputFilters}
                        setInputFilters={setInputFilters}
                        minMaxFilters={minMaxFilters}
                        setMinMaxFilters={setMinMaxFilters}
                        dropdownFilters={dropdownFilters}
                        setDropdownFilters={setDropdownFilters}
                        productsLoading={productsLoading}
                        skeleton={false}
                        isMobile={isMobile}
                        getProducts={props.getProducts}
                        productsAdd={productsAdd}
                        setShowTable={setShowTable}
                        sortOrder={sortOrder}
                        setSortOrder={setSortOrder}
                        showTable={showTable}
                        pageSize={pageSize}
                        setPageSize={handleChangePageSize}
                        pageNumber={pageNumber}
                        setPageNumber={handleChangePageNumber}
                        isChanged={(isTrial || isVisibleLimitRequest) ? (attemptsLeft >= 0 && isChanged) : isChanged}
                        disabledSearch={((isTrial || isVisibleLimitRequest) && (attemptsLeft === 0)) || !Boolean(userInfo?.subscriptions?.[0])}
                        setIsChanged={setIsChanged}
                        setEnabled={setEnabled}
                        currentScroll={currentScroll}
                        showFilters={showFilters}
                        disableShowFilters={disableShowFilters}
          />
          {
            showTable ?
              //condition to toggle table/empty table
              !tableData.length && !fetching && !productsLoading ?
                <div className={cls('product-database-table-wrapper', {
                  'product-database-table-wrapper-empty': !tableData?.length,
                  'active': !showFilters,
                })}>
                  <EmptyTable resetFilters={resetFilters} />
                </div>
                :
                view === 'details' ?
                  <DatabaseProducts fetching={fetching || productsLoading}
                                    loading={loading}
                                    onSort={handleSort}
                                    pageSize={pageSize}
                                    setPageSize={handleChangePageSize}
                                    pageNumber={pageNumber}
                                    setPageNumber={handleChangePageNumber}
                                    data={tableData}
                                    sortOrder={sortOrder}
                                    isChanged={(isTrial || isVisibleLimitRequest) ? false : isChanged}
                                    enabled={enabled}
                                    isTrial={isTrial}
                                    isMobile={isMobile}
                                    isAdmin={isAdmin}
                                    currentScroll={currentScroll}
                                    showFilters={showFilters}
                  />
                  :
                  <DatabaseProductsCards loading={loading}
                                         fetching={fetching || productsLoading}
                                         data={tableData}
                                         sortOrder={sortOrder}
                                         pageSize={pageSize}
                                         setPageNumber={setPageNumber}
                                         isTrial={isTrial}
                                         isMobile={isMobile}
                                         isChanged={(isTrial || isVisibleLimitRequest) ? false : isChanged}
                                         enabled={enabled}
                  />
              :
              null
          }
        </>
      }
      {
        !showTable && <BannerBlock skeleton={firsLoading || filtersLoading}
                                   isMobile={isMobile}
                                   setVisible={setModal}
                                   showFilters={showFilters}
        />
      }
      <LoadPresetModal visible={modal === 'load_preset'}
                       setModal={setModal}
                       setPageSize={setPageSize}
                       setPageNumber={setPageNumber}
                       loadPreset={loadPreset}
                       setLoadedPreset={setLoadedPreset}
                       setSortOrder={setSortOrder}
                       category={'competitors'}
                       loading={loading}
                       isMobile={isMobile}
                       setRecordToDelete={setRecordToDelete}
      />
      <LoadPresetOptionsModal setModal={setModal}
                              isMobile={isMobile}
                              visible={modal === 'load_preset_options'}
                              selected={recordToDelete}
      />
      <DeletePresetModal setModal={setModal}
                         visible={modal === 'delete_preset'}
                         deleteFilter={props.deletePreset}
                         record={recordToDelete}
                         loading={loading}
                         isMobile={isMobile}
      />
      <RenamePresetModal setModal={setModal}
                         visible={modal === 'rename_preset'}
                         record={recordToDelete}
                         isMobile={isMobile}
                         modal={modal}
                         updatePreset={props?.updatePreset}
      />
      <SaveFilterPresetModal setModal={setModal}
                             isMobile={isMobile}
                             visible={modal === 'save_preset'}
                             saveFilterPreset={props.savePreset}
                             filters={!disableShowFilters ?
                               {
                                 switchFilters: { ...switchFilters },
                                 inputFilters: { ...inputFilters },
                                 minMaxFilters: { ...minMaxFilters },
                                 dropdownFilters: { ...dropdownFilters },
                               }
                               :
                               {
                                 global_search: inputText,
                               }}
                             category={'competitors'}
                             loading={loading}
      />
      <VideoTutorialModal visible={modal === 'tutorial'}
                          setModal={setModal}
                          isMobile={isMobile}
                          link={'https://www.youtube.com/watch?v=h-rOBAPwptI'}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  theme: state.nav.theme,
  productDatabase: state.competitors,
  competitor: state.productDatabase.competitor,
});

const mapDispatchToProps = (dispatch) => ({
  getFilters: () => dispatch(Creators.getCompetitorsFiltersRequest()),
  getPresets: () => dispatch(Creators.getCompetitorsPresetsRequest()),
  savePreset: (data) => dispatch(Creators.createCompetitorsPresetRequest(data)),
  deletePreset: (id) => dispatch(Creators.deleteCompetitorsPresetRequest(id)),
  updatePreset: (data) => dispatch(Creators.updateCompetitorsPresetRequest(data)),
  getProducts: (data) => dispatch(Creators.getCompetitorsRequest(data)),
  getShopifyStore: () => dispatch(ShopifyStoreCreators.getShopifyStoresRequest()),
  getAutoStore: () => dispatch(AutoDSCreators.getAutoStoreRequest()),
  setCompetitor: (value) => dispatch(ProductDatabaseCreators.setCompetitorResearch(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompetitorResearchPage);
